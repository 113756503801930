import {
  Activity,
  Category,
  Chart,
  Document,
  Folder,
  MoreCircle,
  Paper,
  PaperDownload,
  People,
  Ticket,
  User,
} from "react-iconly";

import ImgMenuDashboard from "../../assets/images/menu/dashboard.webp";
import ImgMenuPelaporan from "../../assets/images/menu/pelaporan.webp";
import ImgMenuMonitoring from "../../assets/images/menu/monitoring.webp";
import ImgMenuDownloadLaporan from "../../assets/images/menu/download-laporan.webp";
import ImgMenuDownloadSBC from "../../assets/images/menu/download-sbc.webp";
import ImgMenuCekNopen from "../../assets/images/menu/cek-nopen.webp";
import ImgMenuTarikData from "../../assets/images/menu/tarik-data.webp";
import ImgMenuDataSBC from "../../assets/images/menu/data-sbc.webp";
import ImgMenuPenarikan from "../../assets/images/menu/penarikan.webp";
import ImgMenuEvaluasiKerja from "../../assets/images/menu/evaluasi-kerja.webp";
import ImgMenuLampiran from "../../assets/images/menu/lampiran.webp";
import ImgMenuActivityReport from "../../assets/images/menu/activity-report.webp";
import ImgMenuUserRole from "../../assets/images/menu/user-role.webp";
import ImgMenuMasterData from "../../assets/images/menu/master-data.webp";

const pages = [
  {
    header: "Pages",
  },
  {
    id: "dashboard",
    title: "Dashboard",
    image: ImgMenuDashboard,
    icon: <Category set="curved" className="remix-icon" />,
    image: ImgMenuDashboard,
    navLink: "/dashboard",
    access: null,
  },
  {
    id: "pelaporan",
    title: "Pelaporan",
    image: ImgMenuPelaporan,
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/pelaporan-kunjungan",
    access: null,
  },
  {
    id: "monitoring",
    title: "Monitoring",
    image: ImgMenuMonitoring,
    icon: <Chart set="curved" className="remix-icon" />,
    navLink: "/proses-pengajuan",
    access: null,
  },
  {
    id: "download-laporan",
    title: "Download Laporan",
    image: ImgMenuDownloadLaporan,
    icon: <PaperDownload set="curved" className="remix-icon" />,
    navLink: "/download-laporan",
    access: null,
  },
  {
    id: "download-laporan-sbc",
    title: "Download Laporan SBC",
    image: ImgMenuDownloadSBC,
    icon: <PaperDownload set="curved" className="remix-icon" />,
    navLink: "/download-laporan-sbc",
    access: null,
  },
  {
    id: "cek-nopen",
    title: "Cek Nopen",
    image: ImgMenuCekNopen,
    icon: <Document set="curved" className="remix-icon" />,
    navLink: "/cek-nopen",
    access: null,
  },
  {
    id: "tarik-data",
    title: "Tarik Data",
    image: ImgMenuTarikData,
    icon: <Folder set="curved" className="remix-icon" />,
    access: null,
    children: [
      {
        id: "prapen-prioritas",
        title: "Prapen Prioritas",
        navLink: "tarik-data/prapen-prioritas",
        access: null,
      },
      {
        id: "sla-realtime",
        title: "SLA Realtime",
        navLink: "tarik-data/sla-realtime",
        access: null,
      },
      {
        id: "pensiun-asabri",
        title: "Pensiun Asabri",
        navLink: "tarik-data/asabri/pensiun",
        access: null,
      },
      {
        id: "prapen-asabri",
        title: "Prapen Asabri",
        navLink: "tarik-data/asabri/prapen",
        access: null,
      },
      {
        id: "download-tarik-data",
        title: "Download Data Penarikan",
        navLink: "tarik-data/export",
        access: null,
      },
    ],
  },
  {
    id: "data-sbc-pusat",
    title: "Data SBC Pusat",
    image: ImgMenuDataSBC,
    icon: <MoreCircle set="curved" className="remix-icon" />,
    access: null,
    children: [
      {
        id: "prioritas",
        title: "Prioritas Tele",
        navLink: "/priroritas",
        access: null,
      },
    ],
  },
  {
    id: "pelaporan-penarikan",
    title: "Penarikan",
    image: ImgMenuPenarikan,
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/pelaporan-penarikan",
    access: null,
  },
  {
    id: "evaluasi-kerja",
    title: "Evaluasi Kerja",
    image: ImgMenuEvaluasiKerja,
    icon: <Activity set="curved" className="remix-icon" />,
    navLink: "/monitoring",
    access: null,
  },
  {
    id: "lampiran",
    title: "Lampiran",
    image: ImgMenuLampiran,
    icon: <Paper set="curved" className="remix-icon" />,
    navLink: "/lampiran",
    access: null,
  },
  {
    id: "activity-report",
    title: "Activity Report",
    image: ImgMenuActivityReport,
    icon: <Ticket set="curved" className="remix-icon" />,
    navLink: "/activity-report",
    access: null,
  },
  {
    id: "user-role",
    title: "Users & Roles",
    image: ImgMenuUserRole,
    icon: <People set="curved" className="remix-icon" />,
    access: null,
    children: [
      {
        id: "users",
        title: "Users",
        navLink: "/users-roles/users/data",
        access: null,
      },
      {
        id: "roles",
        title: "Roles",
        navLink: "/users-roles/roles/data",
        access: null,
      },
    ],
  },
  {
    id: "master-data",
    title: "Master Data",
    image: ImgMenuMasterData,
    icon: <Folder set="curved" className="remix-icon" />,
    access: null,
    children: [
      // {
      //   id: "nasabah",
      //   title: "Nasabah",
      //   navLink: "/master-data/nasabah/data",
      //   access: null,
      // },
      {
        id: "regional",
        title: "Regional",
        navLink: "/master-data/regional/data",
        access: null,
      },
      {
        id: "area",
        title: "Area",
        navLink: "/master-data/area/data",
        access: null,
      },
      {
        id: "cabang",
        title: "Cabang",
        navLink: "/master-data/cabang/data",
        access: null,
      },
      {
        id: "provinsi",
        title: "Provinsi",
        navLink: "/master-data/provinsi/data",
        access: null,
      },
      {
        id: "kota-kab",
        title: "Kota/Kab",
        navLink: "/master-data/kota-kab/data",
        access: null,
      },
      {
        id: "kecamatan",
        title: "Kecataman",
        navLink: "/master-data/kecamatan/data",
        access: null,
      },
      {
        id: "kelurahan",
        title: "Kelurahan",
        navLink: "/master-data/kelurahan/data",
        access: null,
      },
      {
        id: "bank",
        title: "Bank",
        navLink: "/master-data/bank/data",
        access: null,
      },
      {
        id: "produk",
        title: "Produk",
        navLink: "/master-data/produk/data",
        access: null,
      },
    ],
  },
  {
    id: "profile",
    title: "Profile",
    image: null,
    icon: <User set="curved" className="remix-icon" />,
    navLink: "/profile",
    access: null,
  },
];

export default pages;
