import React, { useEffect, useState } from "react";
import { RiCloseLine, RiMore2Fill } from "react-icons/ri";
import { IoShareOutline } from "react-icons/io5";
import { Alert, Col, Image, Row } from "antd";
import AppIcon from "../../../assets/images/logo/logo-app.png";

export default function AppBanner(props) {
  const [deviceType, setDeviceType] = useState(null);

  function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      setDeviceType("windows-phone");
    }

    if (/android/i.test(userAgent)) {
      setDeviceType("android");
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setDeviceType("ios");
    }
  }

  useEffect(() => {
    getMobileOperatingSystem();
  }, []);

  return (
    <>
      {deviceType === "android" || deviceType === "windows-phone" ? (
        <Alert
          onClose={props.onClose}
          message={
            <div className="hp-d-flex hp-align-items-center">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="hp-border-radius hp-p-4"
                width={"64px"}
                alt={"App Icon"}
              />
              <div className="hp-ml-8">
                <span>Install Aplikasi SIBER dengan tekan </span>
                <span>
                  <IoShareOutline /> pada browser kamu, lalu{" "}
                  <b>Tambah ke Home Screen</b>
                </span>
              </div>
            </div>
          }
          closable
          className="hp-w-100 hp-app-banner hp-bg-color-primary-4 hp-bg-color-dark-primary-1 hp-text-color-primary-1"
          closeIcon={
            <RiCloseLine size={24} className="hp-text-color-primary-1" />
          }
        />
      ) : (
        <Alert
          onClose={props.onClose}
          message={
            <div className="hp-d-flex hp-align-items-center">
              <Image
                style={{ aspectRatio: " 1 / 1" }}
                preview={false}
                src={AppIcon}
                className="hp-border-radius hp-p-4"
                width={"64px"}
                alt={"App Icon"}
              />
              <div className="hp-ml-8">
                <span>Install Aplikasi SIBER dengan tekan </span>
                <span>
                  <RiMore2Fill /> pada browser kamu, lalu{" "}
                  <b>Install Aplikasi</b>
                </span>
              </div>
            </div>
          }
          closable
          className="hp-w-100 hp-app-banner hp-bg-color-primary-4 hp-bg-color-dark-primary-1 hp-text-color-primary-1"
          closeIcon={
            <RiCloseLine size={24} className="hp-text-color-primary-1" />
          }
        />
      )}
    </>
  );
}
