import { lazy } from "react";

const PagesRoutes = [
  // Authentication
  {
    path: "/login",
    component: lazy(() => import("../../view/pages/auth/login")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/register",
    component: lazy(() => import("../../view/pages/auth/register")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/forgot-password",
    component: lazy(() => import("../../view/pages/auth/forgot-password")),
    layout: "FullLayout",
    auth: false,
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../view/pages/auth/reset-password")),
    layout: "FullLayout",
    auth: false,
  },

  // Dashboard
  {
    expact: true,
    path: "/dashboard",
    component: lazy(() => import("../../view/pages/dashboard")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Monitoring
  {
    path: "/proses-pengajuan",
    component: lazy(() => import("../../view/pages/monitoring/data")),
    layout: "VerticalLayout",
    auth: true,
  },

  // User & Roles
  {
    path: "/users-roles/roles/data",
    component: lazy(() => import("../../view/pages/users-roles/roles/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/users-roles/roles/form",
    component: lazy(() => import("../../view/pages/users-roles/roles/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/users-roles/users/data",
    component: lazy(() => import("../../view/pages/users-roles/users/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/users-roles/users/form",
    component: lazy(() => import("../../view/pages/users-roles/users/form")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Master Data - Regional
  {
    path: "/master-data/regional/data",
    component: lazy(() => import("../../view/pages/master-data/regional/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/regional/form",
    component: lazy(() => import("../../view/pages/master-data/regional/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Area
  {
    path: "/master-data/area/data",
    component: lazy(() => import("../../view/pages/master-data/area/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/area/form",
    component: lazy(() => import("../../view/pages/master-data/area/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Cabang
  {
    path: "/master-data/cabang/data",
    component: lazy(() => import("../../view/pages/master-data/cabang/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/cabang/form",
    component: lazy(() => import("../../view/pages/master-data/cabang/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Provinsi
  {
    path: "/master-data/provinsi/data",
    component: lazy(() => import("../../view/pages/master-data/provinsi/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/provinsi/form",
    component: lazy(() => import("../../view/pages/master-data/provinsi/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Kota-Kab
  {
    path: "/master-data/kota-kab/data",
    component: lazy(() => import("../../view/pages/master-data/kota-kab/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/kota-kab/form",
    component: lazy(() => import("../../view/pages/master-data/kota-kab/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Kecamatan
  {
    path: "/master-data/kecamatan/data",
    component: lazy(() =>
      import("../../view/pages/master-data/kecamatan/data")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/kecamatan/form",
    component: lazy(() =>
      import("../../view/pages/master-data/kecamatan/form")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Kelurahan-Desa
  {
    path: "/master-data/kelurahan/data",
    component: lazy(() =>
      import("../../view/pages/master-data/kelurahan/data")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/kelurahan/form",
    component: lazy(() =>
      import("../../view/pages/master-data/kelurahan/form")
    ),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Bank
  {
    path: "/master-data/bank/data",
    component: lazy(() => import("../../view/pages/master-data/bank/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/bank/form",
    component: lazy(() => import("../../view/pages/master-data/bank/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Master Data - Produk
  {
    path: "/master-data/produk/data",
    component: lazy(() => import("../../view/pages/master-data/produk/data")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/master-data/produk/form",
    component: lazy(() => import("../../view/pages/master-data/produk/form")),
    layout: "VerticalLayout",
    auth: true,
  },
  // Profile
  {
    path: "/profile",
    component: lazy(() => import("../../view/pages/profile")),
    layout: "VerticalLayout",
    auth: true,
  },
  {
    path: "/setting/profile",
    component: lazy(() => import("../../view/pages/setting/profile")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Activity Report
  {
    path: "/activity-report",
    component: lazy(() => import("../../view/pages/activity-report")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Cek Nopen
  {
    path: "/cek-nopen",
    component: lazy(() => import("../../view/pages/cek-nopen")),
    layout: "VerticalLayout",
    auth: true,
  },

  // Lampiran
  {
    path: "/lampiran",
    component: lazy(() => import("../../view/pages/lampiran")),
    layout: "VerticalLayout",
    auth: true,
  },
];

export default PagesRoutes;
